import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    CircularProgress,
    Box,
    Skeleton,
    Drawer,
    IconButton
} from '@mui/material';
import {
    Timeline,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    timelineItemClasses,
    TimelineConnector
} from '@mui/lab';

import { format, parse } from 'date-fns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceIcon from '@mui/icons-material/Place';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TruckIcon from '../../../assets/images/cargo-truck.png';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import useScriptRef from 'hooks/useScriptRef';

import axios from 'axios';
import { useDispatch } from 'react-redux';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { IconTruckDelivery, IconTruckLoading, IconUser, IconPhone, IconDownload } from '@tabler/icons';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
// import WrappedMap from './Map';
// import Map from './Map';
import 'react-perfect-scrollbar/dist/css/styles.css';
import truckImg from 'assets/images/truck_img.png';
import LoadStatus from 'ui-component/buttons/LoadStatus';
import { fontFamily } from '@mui/system';

// ===========================|| FIREBASE - REGISTER ||=========================== //
const containerStyle = {
    width: '100%',
    height: '650px'
};
const defaultLocation = { lat: 40.756795, lng: -74.954298 };
let directionsService;

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 9,
        left: 'calc(-50% + 14px)',
        right: 'calc(50% + 14px)'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00970F'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00970F'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.orange.main,
        borderTopWidth: 4,
        borderRadius: 1
    }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.orange,
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#00970F !important'
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#00970F',
        zIndex: 1,
        fontSize: 24
    },
    '& .QontoStepIcon-remainIcon': {
        color: ownerState.active ? '#00970F' : theme.palette.orange.main,
        zIndex: 1,
        fontSize: 24
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    }
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <CircleOutlinedIcon className="QontoStepIcon-remainIcon" />}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool
};

const steps = ['New', 'Heading Pickup', 'Checked In', 'Loaded', 'In Transit', 'Delivered'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const ViewLoad = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM'
    });
    const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDDLFPFqXmtMzrzbPJrMn5osR4PA2swrtM`;
    const [state, setState] = useState({
        directions: null,
        bounds: null
    });
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const cardStyles = {
        padding: '0 !important',
        ...(matchDownSM && { height: '62rem' }),
        ...(!matchDownSM && { height: '55.5rem' })
    };
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const [paths, setPaths] = useState();
    const [stops, setStops] = useState();
    const [progress, setProgress] = useState();

    const [loading, setLoading] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loadData, setLoadData] = useState();
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [msg, setMsg] = useState('');
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedTruck, setSelectedTruck] = useState(null);

    const [isMapLoading, setIsMapLoading] = useState(false);

    const [isLoadLoading, setIsLoadLoading] = useState(false);

    const [vehicles, setVehicles] = useState([]);

    useEffect(() => {
        setUrl(window.location.origin + `/dispatch-center/load/view-status-public/${id}`);
    }, []);

    const prevDocModalHanler = () => {
        setOpen(true);
    };

    const handleDocCloseModal = () => {
        setOpen(false);
    };

    // const [origin, setOrigin] = useState({ lat: 37.7749, lng: -122.4194 }); // New York City
    const [directions, setDirections] = useState(null);

    const centerPath = paths && parseInt(paths.length / 2);
    const centerPathLat = paths && paths[centerPath] && paths[centerPath].lat;
    const centerpathLng = paths && paths[centerPath + 5] && paths[centerPath + 5].lng;

    const center = {
        lat: paths ? centerPathLat : 12.9771191896563,
        lng: paths ? centerpathLng : 77.5857120256672
    };

    const destinations = [
        {
            location: {
                id: 'ID of FIrst Place',
                lat: 36.04973979999999755819,
                lng: -94.17729500000000086857
            }
        },
        {
            location: {
                id: 'ID of Second Place',
                lat: 40.753167,
                lng: -73.96812
            }
        },
        {
            location: {
                id: 'ID of Third Place',
                lat: 40.853167,
                lng: -73.96812
            }
        }
    ];

    let icon1 = {
        url: 'https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png',
        // scaledSize: new window.google.maps.Size(40, 40),
        // anchor: new window.google.maps.Point(20, 20),
        scale: 0.7
    };

    const directionsCallback = (response) => {
        if (response !== null) {
            setDirections(response);
        }
    };

    const copyHandler = () => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setMsg('Text copied to clipboard');
            })
            .catch((error) => {
                setMsg('Failed to copy text: ', error);
            });
    };
    useEffect(() => {
        if (id && !loadData) {
            fetchLoad(id);
        }
    }, [loadData, id]);

    const fetchLoad = async (id) => {
        setLoading(true);
        try {
            const { data } = await axios.get(`/v1/api/dispatch/load/view-maps-public/${id}/`);

            setLoadData(data);
            setPaths(data.map_data.paths);
            setProgress(data.map_data.progress);
            setStops(data.map_data.stops);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getActiveStep = (step) => {
        return steps.findIndex((i) => i === step);
    };

    let icon = {};

    const onMapLoad = (map) => {
        directionsService = new google.maps.DirectionsService();

        icon = {
            url: truckImg,
            scaledSize: new window.google.maps.Size(40, 40),
            anchor: new window.google.maps.Point(20, 20),
            scale: 0.7
        };

        // directionsService.route.

        // icon1.scaledSize = new window.google.maps.Size(40, 40);
        // icon1.anchor = new window.google.maps.Point(20, 20);

        const routesCopy =
            loadData && loadData.loads.length > 0
                ? loadData.loads.map((route) => {
                      return {
                          location: { lat: parseFloat(route.latitude), lng: parseFloat(route.longitude) },
                          stopover: true
                      };
                  })
                : [];

        //   loadData && loadData.loads.length > 0
        //       ? loadData.loads.map((route) => {
        //             return {
        //                 location: { lat: route.latitude, lng: route.longiutde },
        //                 stopover: true
        //             };
        //         })
        //       : [];

        const origin = routesCopy.length === 1 ? new google.maps.LatLng(routesCopy[0].location.lat, routesCopy[0].location.lng) : routesCopy.shift().location;
        const destination =
            routesCopy.length === 1 ? new google.maps.LatLng(routesCopy[0].location.lat, routesCopy[0].location.lng) : routesCopy.pop().location;
        //put all the remaining coordinates in waypoints after(pop and shift)
        const waypoints = routesCopy;

        //call getDirection function
        getDirection(origin, destination, waypoints);
    };

    const fetchData = async () => {
        // if (loginInfoFromStorage.user_type === 'dispatcher_admin') {
        try {
            setIsMapLoading(true);
            const { data } = await axios.get(`/v1/api/dispatch/load/eld-load-data-public/${id}`);
            setVehicles(data.vehicles);

            setIsMapLoading(false);
            fetchLoad(id);
            toast.success('Location updated successfully');
        } catch (error) {
            console.log(error.response.data.error);
            setIsMapLoading(false);
            if (error?.response?.data?.error) {
                toast.error(error?.response?.data?.error);
            } else {
                toast.error('Please contact with support');
            }
            console.log(error);
        }
        // }
    };

    const getDirection = (origin, destination, waypoints) => {
        //this will check if there is a waypoint meaning the array  has 3 or more coordinates
        waypoints.length >= 1
            ? directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: google.maps.TravelMode.DRIVING,
                      waypoints: waypoints
                  },
                  (result, status) => {
                      if (status === google.maps.DirectionsStatus.OK) {
                          //changing the state of directions to the result of direction service
                          setState({
                              directions: result
                          });
                      } else {
                          console.error(`error fetching directions ${result}`);
                      }
                  }
              )
            : directionsService.route(
                  {
                      origin: origin,
                      destination: destination,
                      travelMode: google.maps.TravelMode.DRIVING
                  },
                  (result, status) => {
                      if (status === google.maps.DirectionsStatus.OK) {
                          //changing the state of directions to the result of direction service
                          setState({
                              directions: result
                          });
                      } else {
                          console.error(`error fetching directions ${result}`);
                      }
                  }
              );
    };

    const openDrawerHandler = () => {
        setOpenDrawer(true);
    };

    const closeDrawerHandler = () => {
        setOpenDrawer(false);
    };

    const renderFileType = (fileType) => {
        if (fileType === 'bol_doc') {
            return 'BOL';
        } else if (fileType === 'arc_doc') {
            return 'RC';
        } else if (fileType === 'inv_doc' || fileType === 'ainv_doc') {
            return 'Invoice';
        } else {
            return 'Other Doc';
        }
    };

    return (
        <>
            <Card sx={{ borderColor: 'primary', border: '3px solid', borderRadius: '10px' }}>
                <CardContent sx={{ ...cardStyles }}>
                    {/* <PerfectScrollbar> */}
                    {loading ? (
                        <Skeleton height={40} />
                    ) : (
                        <Grid container spacing={3} sx={{ px: 6, py: 4 }}>
                            <Grid xl={3} lg={3} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                                <Typography variant="h2">Load - {loadData && loadData.load}</Typography>
                            </Grid>
                            <Grid xl={3} lg={3} md={12} sm={12} xs={12} sx={{ mt: 1.5 }}>
                                <LoadStatus
                                    sx={{ fontSize: '1.2rem', fontFamily: 'Lexend', padding: '6px' }}
                                    text={loadData && loadData.load_status}
                                    type={
                                        loadData && loadData.load_status === 'Checked In'
                                            ? 'info'
                                            : loadData && loadData.load_status === 'Delivered'
                                            ? 'success'
                                            : loadData && loadData.load_status === 'Loaded'
                                            ? 'warning'
                                            : loadData && loadData.load_status === 'In Transit'
                                            ? 'warning'
                                            : loadData && loadData.load_status === 'New'
                                            ? 'na'
                                            : loadData && loadData.load_status === 'Heading Pickup'
                                            ? 'hp'
                                            : loadData && loadData.load_status === 'Heading Delivery'
                                            ? 'hp'
                                            : 'na'
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    {loading ? (
                        <Skeleton height={150} />
                    ) : (
                        <Grid container spacing={1}>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                {loading && <Skeleton height={150} />}
                                {loadData && isLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={center}
                                        zoom={4}
                                        options={{
                                            zoomControl: true,
                                            streetViewControl: false,
                                            mapTypeControl: false,
                                            fullscreenControl: false
                                        }}
                                        onLoad={(map) => {
                                            onMapLoad(map);
                                        }}
                                    >
                                        {stops.map((stop, index) => (
                                            <Marker
                                                key={index}
                                                position={{
                                                    lat: stop.lat,
                                                    lng: stop.lng
                                                }}
                                                title={stop.id}
                                                label={`${index + 1}`}
                                                onClick={() => {
                                                    setSelectedMarker(stop);
                                                }}
                                            />
                                        ))}

                                        {selectedMarker && (
                                            <InfoWindow
                                                position={{
                                                    lat: parseFloat(selectedMarker?.lat),
                                                    lng: parseFloat(selectedMarker?.lng)
                                                }}
                                                options={{
                                                    pixelOffset: new google.maps.Size(0, -40),
                                                    closeButton: false,
                                                    boxStyle: {
                                                        width: '150px',
                                                        backgroundColor: 'red'
                                                    }
                                                }}
                                                onCloseClick={() => setSelectedMarker(null)}
                                            >
                                                <Box style={{ backgroundColor: '#0000001c', padding: '12px' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: 'white',
                                                            display: 'flex',
                                                            padding: '10px',
                                                            flexDirection: 'column',
                                                            borderRadius: '5px'
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', gap: '30px' }}>
                                                            <div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                    <PlaceIcon style={{ color: '#ff0000a3' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.address}</h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                    <BusinessIcon style={{ color: '#0b5b0bbd' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.cust} </h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                    <CalendarMonthIcon style={{ color: '#0b565bbd' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px' }}>{selectedMarker && selectedMarker.date} </h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                    <LocalShippingIcon style={{ color: '#0b565bbd' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px', textTransform: 'capitalize' }}>
                                                                                {selectedMarker && selectedMarker.load_type}{' '}
                                                                            </h3>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                textAlign: 'center',
                                                                marginTop: '15px',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: '#00000091',
                                                                    fontWeight: '500',
                                                                    fontSize: '15px',
                                                                    marginTop: '20px',
                                                                    display: 'block'
                                                                }}
                                                            >
                                                                {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                                                            </span>

                                                            <Button
                                                                variant="contained"
                                                                sx={{ color: 'white', backgroundColor: '#EB812E' }}
                                                                size="small"
                                                                color="orange"
                                                                onClick={() => setSelectedMarker(null)}
                                                            >
                                                                Close
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </InfoWindow>
                                        )}

                                        {progress && (
                                            <>
                                                <Marker
                                                    icon={TruckIcon}
                                                    position={progress[progress.length - 1]}
                                                    onClick={() => setSelectedTruck(progress[progress.length - 1])}
                                                />
                                            </>
                                        )}

                                        {selectedTruck && (
                                            <InfoWindow
                                                position={{
                                                    lat: parseFloat(selectedTruck?.lat),
                                                    lng: parseFloat(selectedTruck?.lng)
                                                }}
                                                options={{
                                                    pixelOffset: new google.maps.Size(0, -40),
                                                    closeButton: false,
                                                    boxStyle: {
                                                        width: '150px',
                                                        backgroundColor: 'red'
                                                    }
                                                }}
                                                onCloseClick={() => setSelectedTruck(null)}
                                            >
                                                <Box style={{ backgroundColor: '#0000001c', padding: '12px' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: 'white',
                                                            display: 'flex',
                                                            padding: '10px',
                                                            flexDirection: 'column',
                                                            borderRadius: '5px',
                                                            minWidth: '200px'
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', gap: '30px' }}>
                                                            <div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                    <PlaceIcon style={{ color: '#ff0000a3' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px' }}>Located At</h3>
                                                                            <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                                {selectedTruck && selectedTruck.description}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                    <CalendarMonthIcon style={{ color: '#0b565bbd' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px' }}>Updated At </h3>
                                                                            <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                                {selectedTruck && new Date(selectedTruck.located_at).toLocaleTimeString()}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '20px' }}>
                                                                    <AccessTimeIcon style={{ color: '#0b565bbd' }} />
                                                                    {isLoadLoading ? (
                                                                        <Skeleton height={'30px'} width={'100px'} style={{ display: 'block' }} />
                                                                    ) : (
                                                                        <div>
                                                                            <h3 style={{ margin: '0px', textTransform: 'capitalize' }}>ETA</h3>
                                                                            <span style={{ color: '#00000091', fontWeight: '500', fontSize: '12px' }}>
                                                                                {loadData && loadData.map_data.eta}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                textAlign: 'center',
                                                                marginTop: '15px',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <span></span>

                                                            <Button
                                                                variant="contained"
                                                                sx={{ color: 'white', backgroundColor: '#EB812E' }}
                                                                size="small"
                                                                color="orange"
                                                                onClick={() => setSelectedTruck(null)}
                                                            >
                                                                Close
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </InfoWindow>
                                        )}

                                        {loadData && loadData.is_active && (
                                            <Tooltip title="Get Location Update" placement="top-start">
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '15vh',
                                                        right: '0.5vw',
                                                        backgroundColor: 'white',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {isMapLoading ? (
                                                        <CircularProgress size={35} />
                                                    ) : (
                                                        <SyncIcon
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'orange',
                                                                fontSize: '35px'
                                                            }}
                                                            onClick={fetchData}
                                                        />
                                                    )}
                                                </div>
                                            </Tooltip>
                                        )}
                                    </GoogleMap>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {loading ? (
                        <Skeleton height={40} />
                    ) : (
                        <Stack direction={matchDownSM ? 'column' : 'row'} justifyContent="space-between" alignItems="center" sx={{ mx: !matchDownSM ? 4 : 0 }}>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
                                <img src={loadData && loadData.comp_info.logo} alt="test" height="100px" width="100px" />
                                <Typography variant="h2">{loadData && loadData.comp_info.name}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
                                <Button
                                    variant="contained"
                                    sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', mt: 4 }}
                                    color="orange"
                                    onClick={openDrawerHandler}
                                    size="medium"
                                >
                                    SEE INFO
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E', mt: 4 }}
                                    color="orange"
                                    onClick={prevDocModalHanler}
                                    size="medium"
                                >
                                    SHARE NOW
                                </Button> */}
                            </Stack>
                        </Stack>
                    )}
                    {/* </PerfectScrollbar> */}
                </CardContent>
            </Card>
            {/* <Dialog
                open={open}
                onClose={handleDocCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle variant="h3" id="alert-dialog-title">
                    {'Dispatch Center Load View'}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            className="text-input"
                            color="orange"
                            type="text"
                            value={`${url}`}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                        <Button variant="text" onClick={copyHandler}>
                            Copy
                        </Button>
                        <Button
                            variant="text"
                            onClick={() => navigate(`/dispatch-center/load/view-status-public/${id}`)}
                            size="medium"
                            // sx={{ pt: 1.5, pb: 1.5 }}
                        >
                            Preview
                        </Button>
                    </Stack>
                    <Typography sx={{ mt: 1 }} variant="h5">
                        {msg ? msg : ''}
                    </Typography>
                </DialogContent>
            </Dialog> */}

            <Drawer anchor="bottom" open={openDrawer} onClose={closeDrawerHandler}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ borderBottom: '1px solid black' }}>
                    <Typography variant="h2" sx={{ ml: 2 }}>
                        Load Information
                    </Typography>
                    <IconButton onClick={closeDrawerHandler}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {loading ? (
                    <Skeleton height={200} />
                ) : (
                    <Grid container spacing={5}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0
                                    }
                                }}
                            >
                                {loadData &&
                                    loadData.loads &&
                                    loadData.loads.map((i, index) => {
                                        return (
                                            <TimelineItem key={`${i.dsp_load_type_id}-${i.company_name}`}>
                                                <TimelineOppositeContent style={{ display: 'none' }} />

                                                <TimelineSeparator>
                                                    <TimelineDot
                                                        color={i.load_steps && i.load_steps.is_completed === true ? 'success' : 'secondary'}
                                                        variant={i.load_steps && i.load_steps.is_completed === true ? 'filled' : 'outlined'}
                                                    />
                                                    {index !== loadData.loads.length - 1 && <TimelineConnector />}
                                                </TimelineSeparator>

                                                <TimelineContent>
                                                    <Grid container spacing={2} sx={{ mb: 3 }}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Typography variant="h2" color="primary" sx={{ mt: 0, mb: 2 }}>
                                                                {i.company_name} - <span style={{ textTransform: 'capitalize' }}>{i.load_type}</span>
                                                            </Typography>
                                                            <Typography variant="body1" color="primary" sx={{ fontSize: '14px', mb: 1 }}>
                                                                {i.city_name}, {i.state_name}
                                                            </Typography>
                                                            <Typography variant="body1" color="primary" sx={{ fontSize: '14px', mb: 1 }}>
                                                                {i.address}, {i.zip_code}
                                                            </Typography>
                                                            <Typography variant="body1" color="primary" spacing={2} sx={{ fontSize: '14px', mb: 2 }}>
                                                                {i.date && format(new Date(i.date + 'T00:00'), 'MMM d yyyy')}
                                                                <span>
                                                                    {' '}
                                                                    {i.time && i.time !== '' && format(parse(i.time, 'HH:mm:ss', new Date()), 'h:mm a')}
                                                                </span>
                                                            </Typography>

                                                            <LoadStatus
                                                                sx={{ fontSize: '0.8rem', fontFamily: 'Lexend', padding: '6px' }}
                                                                text={i.load_steps && i.load_steps.step}
                                                                type={
                                                                    i.load_steps && i.load_steps.step === 'Checked In'
                                                                        ? 'info'
                                                                        : i.load_steps && i.load_steps.step === 'Delivered'
                                                                        ? 'success'
                                                                        : i.load_steps && i.load_steps.step === 'Loaded'
                                                                        ? 'warning'
                                                                        : i.load_steps && i.load_steps.step === 'In Transit'
                                                                        ? 'warning'
                                                                        : i.load_steps && i.load_steps.step === 'New'
                                                                        ? 'na'
                                                                        : i.load_steps && i.load_steps.step === 'Heading Pickup'
                                                                        ? 'hp'
                                                                        : i.load_steps && i.load_steps.step === 'Heading Delivery'
                                                                        ? 'hp'
                                                                        : ''
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TimelineContent>
                                            </TimelineItem>
                                        );
                                    })}
                            </Timeline>
                        </Grid>

                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={3} sx={{ mt: 3 }}>
                                <Typography variant="h2" gutterBottom>
                                    Overview
                                </Typography>
                                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                                    <Typography variant="h3" gutterBottom>
                                        Customer
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                        {loadData && loadData.cust_name}
                                    </Typography>
                                </Stack>
                                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                                    <Typography variant="h3" gutterBottom>
                                        Driver
                                    </Typography>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <IconUser color="#eb812e" size={30} />
                                        <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                            {loadData && loadData.driver_info.name}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <IconPhone color="#eb812e" size={30} />
                                        <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                            {loadData && loadData.driver_info.phone}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <IconTruckDelivery color="#eb812e" size={30} />
                                        <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                            {loadData && loadData.truck_license}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                        <IconTruckLoading color="#eb812e" size={30} />
                                        <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                            {loadData && loadData.trailer_license}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                {loadData && loadData.docs && loadData.docs.length > 0 && (
                                    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                                        <Typography variant="h3" gutterBottom>
                                            Files
                                        </Typography>
                                        {loadData.docs.map((i) => (
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} key={i.dsp_load_doc_id}>
                                                <IconButton onClick={() => window.open(i.oth_doc, '_blank')}>
                                                    <IconDownload color="#eb812e" size={30} />
                                                </IconButton>
                                                <Typography variant="body1" sx={{ fontSize: '18px' }}>
                                                    {i.oth_doc_name} - {renderFileType(i.doc_type)}
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </Drawer>
        </>
    );
};

export default ViewLoad;
